<template>
  <a href="#" class="flex justify-between">
    <p>Filter</p>
    <div class="icon-filter-1 text-4xl -mt-3"></div>
  </a>
</template>

<script>
export default {
  name: 'date-filter',
  props: ['value'],
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<style></style>
